import React from "react";
import { FaLaravel, FaVuejs, FaCode } from "react-icons/fa";

const BOOTSTRAP_FOR_SKILL_ICON = "text-4xl mx-auto inline-block";
const data = {
  name: "Franco Sanllehi",
  title: "Back-End Developer/Freelancer",
  social: {
    github: "https://github.com/DmACKGL",
    twitter: "https://twitter.com/SanllehiFranco",
    linkedin: "https://www.linkedin.com/in/franco-sanllehi-romero-311aa211a/",
    email: "francosanllehi@gmail.com",
  },
  about: {
    title: "My Background",
    description:
      "I am a Chilean programmer with more than 10 years in the high-level, mission-critical application and game server industry. I am always open to new challenges and technologies. A complete fan of airplanes and airports.",
  },
  skills: [
    {
      skillName: "Back-End",
      skillIcon: <FaCode className={BOOTSTRAP_FOR_SKILL_ICON} />,
    },
    {
      skillName: "VueJS",
      skillIcon: <FaVuejs className={BOOTSTRAP_FOR_SKILL_ICON} />,
    },
    {
      skillName: "Laravel",
      skillIcon: <FaLaravel className={BOOTSTRAP_FOR_SKILL_ICON} />,
    },
  ],
  projects: [
    {
      title: "React tailwind portfolio",
      description: "👨‍🎨 An open-source portfolio template built with React and Tailwind.",
      tags: [
        "template",
        "portfolio",
        "reactjs",
        "tailwindcss"
      ],
      link: "https://github.com/braydentw/react-tailwind-portfolio"
    },
    {
      title: "My personal website",
      description: "⚡ My portfolio built with NextJS and TailwindCSS.",
      tags: [
        "website",
        "portfolio",
        "nextjs",
        "tailwindcss"
      ],
      link: "https://github.com/braydentW/braydentw"
    }
  ]
};
export default data;
